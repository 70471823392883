import * as React from 'react';
import Layout from '../components/layout';
import {PRIVACY_POLICY} from '../components/privacy';

const PrivacyPage = () => {
  return (
    <Layout pagePath="/privacypolicy">
      <div className="container mx-auto py-16 px-4 sm:px-0">
        <h1 className="m-0 mb-4 text-4xl"> Privacy Policy </h1>
        {PRIVACY_POLICY}
      </div> 
    </Layout>
  )
};

export default PrivacyPage;